<script>
import useVuelidate from '@vuelidate/core'
import {required, requiredIf, email} from '@vuelidate/validators'
import {FilterMatchMode} from 'primevue/api';

/* COMPOSANTS */
import AkFormList from "@components/layout/AkFormList";
import AkInputText from "@components/input/AkInputText";
import AkDropdown from "@components/input/AkDropdown";
import AkDialog from "@components/general/AkDialog";

/* MIXINS */
import randomRef from "@mixins/randomRef";
import utilsMixin from "@mixins/utilsMixin";
import roleMixin from "@mixins/roleMixin";
import messageMixin from "@mixins/messageMixin";

/* SERVICES */
import agerService from "@services/agerService";
import uerService from "@services/uerService";
import ceiService from "@services/ceiService";
import userService from "@services/userService";
import userGroupService from "@services/userGroupService";
import rolePermissionService from "@services/rolePermissionService";
import userGroupLinkService from "@services/userGroupLinkService";
import AkDropdownMultiple from "@components/input/AkDropdownMultiple.vue";
import AkTitle from "@components/input/AkTitle.vue";
import AkFileUpload from "@components/input/AkFileUpload.vue";
import mobileMixin from "@mixins/mobileMixin";

export default {
  components: {AkFileUpload, AkTitle, AkDropdownMultiple, AkFormList, AkInputText, AkDropdown, AkDialog},
  mixins: [randomRef, utilsMixin, roleMixin, messageMixin, mobileMixin],
  metaInfo() {
    return {
      title: "user.list"
    }
  },
  setup() {
    return {v$: useVuelidate()}
  },
  data() {
    return {
      loading: false,
      list: [],
      current: {
        affectationsDTO: {affectations: []},
      },

      agerList: [],
      uerList: [],
      ceiList: [],
      ugList: [],
      relatedUerList: [],
      relatedCeiList: [],
      userGroupLinks: [],
      filters: {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS}
      },
      newAffectation: {},
      messagesAddAffectationPopup: [],
      messagesUserPopup: [],
      addAffectationPopupOpened: false,
      files: [],
      isExportDownloading: false,
    }
  },
  validations() {
    return {
      current: {
        lastName: {required: requiredIf(!this.addAffectationPopupOpened)},
        firstName: {required: requiredIf(!this.addAffectationPopupOpened)},
        password: {required: requiredIf(!this.addAffectationPopupOpened && !this.current.id && this.isPasswordRequired)},
        email: {required: requiredIf(!this.addAffectationPopupOpened && this.isLoginRequired), email},
        groupIds: {required},
      },
      newAffectation: {
        agerId: {required: requiredIf(this.addAffectationPopupOpened && this.newAffectation.agerId === undefined && !this.isAgerRequirementsMetForUser(this.current))},
        uerId: {required: requiredIf(this.addAffectationPopupOpened && this.newAffectation.uerId === undefined && !this.isUerRequirementsMetForUser(this.current))},
        ceiId: {required: requiredIf(this.addAffectationPopupOpened && this.newAffectation.ceiId === undefined && !this.isCeiRequirementsMetForUser(this.current))}
      }
    }
  },
  mounted() {
    this.getRef().showTotalLoader();
    this.loading = true;
    let p0 = rolePermissionService.authorizeAccessByPermission(this.permissionConst.admin.view);

    let p1 = agerService.findAllCurrentForAdmin();
    p1.then(data => this.agerList = data);

    let p2 = uerService.findAllCurrent();
    p2.then(data => {
      this.uerList = data;
      this.relatedUerList = data;
    });

    let p3 = ceiService.findAllCurrent();
    p3.then(data => {
      this.ceiList = data;
      this.relatedCeiList = data;
    });

    let p4 = userService.findAllWithGroups();
    p4.then(data => this.list = data);

    let p5 = userGroupService.findAll();
    p5.then(data => {
      this.ugList = data;
    });

    Promise.all([p0, p1, p2, p3, p4, p5]).then(() => {
      this.loading = false;
      this.getRef().hideLoader();
    });
  },
  methods: {
    exportList() {
      this.isExportDownloading = true;
      userService.exportList().finally(() => {
        this.isExportDownloading = false;
      })
    },
    openImportDialog() {
      this.$refs.dialogImport.show();
    },
    create() {
      this.getRef().resetMessages();
      this.v$.$touch();
      if (this.v$.$error) return;

      this.getRef().showTotalLoader();
      if (!this.isAgerRequiredForGroups(this.current.groups)) this.current.ceiId = null;
      if (!this.isAgerRequiredForGroups(this.current.groups)) this.current.uerId = null;
      if (!this.isAgerRequiredForGroups(this.current.groups)) this.current.agerId = null;

      userService.createWithGroups(this.current).then(data => {
        this.list.push(data);
        this.current = {
          affectationsDTO: {affectations: []},
        };

        this.getRef().success(this.$t("user.added"));
        this.$refs.dialogCreate.hide();
      }).catch(e => {
        if (e.response) {
          if (e.response.status === 409) this.getRef().error(this.$t('login_already_exists'));
        } else {
          this.getRef().error(this.$t('unknown_error'));
        }
      }).finally(() => {
        this.getRef().hideLoader();
      });
    },
    update() {
      if (this.current.password === null) this.current.password = '';
      this.getRef().resetMessages();
      this.resetMessagesCustom(this.messagesUserPopup);

      if (!this.isUserAffectationsValid(this.current)) {
        this.errorCustom(this.messagesUserPopup, this.$t('user.affectations_not_valid'));
        return;
      }

      this.v$.$touch();
      if (this.v$.$error) return;

      this.loading = true;

      this.getRef().showTotalLoader();
      if (!this.isAgerRequiredForGroups(this.current.groups)) this.current.ceiId = null;
      if (!this.isAgerRequiredForGroups(this.current.groups)) this.current.uerId = null;
      if (!this.isAgerRequiredForGroups(this.current.groups)) this.current.agerId = null;

      userService.updateWithGroups(this.current).then(data => {
        this.replaceInListById(this.list, data);
        this.current = {};

        this.getRef().success(this.$t("user.updated"));
        this.$refs.dialogUpdate.hide();
      }).catch(e => {
        if (e.response) {
          if (e.response.status === 409) this.getRef().error(this.$t('login_already_exists'));
        } else {
          this.getRef().error(this.$t('unknown_error'));
        }
      }).finally(() => {
        this.loading = false;
        this.getRef().hideLoader();
      });
    },
    delete() {
      this.getRef().resetMessages();
      userService.delete(this.current).then(data => {
        this.removeInListById(this.list, data.id);
        this.getRef().success(this.$t("user.deleted"))
      }).catch(e => {
        this.getRef().error(e.response.data.error);
      });
    },
    isUserAffectationsValid(user) {
      return this.isAgerRequirementsMetForUser(user) && this.isUerRequirementsMetForUser(user) && this.isCeiRequirementsMetForUser(user);
    },
    openCreateDialog() {
      this.loading = true;
      this.current = {
        affectationsDTO: {affectations: []},
      };
      this.current.affectationsToDelete = [];
      this.loading = false;
      this.$refs.dialogCreate.show();
    },
    openUpdateDialog(data) {
      this.current = {...data};
      if (this.current.affectationsToDelete !== undefined && this.current.affectationsToDelete.length > 0) {
        this.current.affectationsDTO.affectations = this.current.affectationsDTO.affectations.concat(this.current.affectationsToDelete);
      }
      this.current.affectationsToDelete = [];
      this.$refs.dialogUpdate.show();
    },
    openDeleteDialog(data) {
      this.current = data;
      this.$refs.dialogDelete.show();
    },
    deleteAffectation(data) {
      let affectation = this.current.affectationsDTO.affectations.find(a => a.id === data.id);
      this.current.affectationsToDelete.push(affectation);
      this.removeInListById(this.current.affectationsDTO.affectations, affectation.id);
      this.replaceInListById(this.list, this.current);
    },
    openAddAffectationDialog() {
      this.newAffectation = {};
      this.messagesAddAffectationPopup = [];
      this.addAffectationPopupOpened = true;
      this.$refs.dialogAddAffectation.show();
    },
    addAffectation() {
      this.v$.$touch();
      if (this.v$.$error) {
        this.errorCustom(this.messagesAddAffectationPopup, this.$t('user.affectations_not_valid'))
        return;
      }

      if (this.newAffectation.agerId === undefined) {
        this.newAffectation.agerId = null;
        this.newAffectation.agerLabel = null;
      } else {
        this.newAffectation.agerLabel = this.agerList.find(ager => ager.id === this.newAffectation.agerId)?.label;
      }

      if (this.newAffectation.uerId === undefined) {
        this.newAffectation.uerId = null;
        this.newAffectation.uerLabel = null;
      } else {
        this.newAffectation.uerLabel = this.uerList.find(uer => uer.id === this.newAffectation.uerId)?.label;
      }

      if (this.newAffectation.ceiId === undefined) {
        this.newAffectation.ceiId = null;
        this.newAffectation.ceiLabel = null;
      } else {
        this.newAffectation.ceiLabel = this.ceiList.find(cei => cei.id === this.newAffectation.ceiId)?.label;
      }

      this.current.affectationsDTO.affectations.push(this.newAffectation);
      this.newAffectation = {};
      this.$refs.dialogAddAffectation.hide();
      this.addAffectationPopupOpened = false;
    },
    cancelAddAffectation() {
      if (this.current.id === undefined) return;
      this.loading = true;
      this.newAffectation = {};
      this.relatedCeiList = this.ceiList;
      this.relatedUerList = this.uerList;
      let p1 = userService.findByIdWithGroups(this.current.id);
      p1.then(data => {
        this.current = data;
        this.replaceInListById(this.list, this.current);
      });

      Promise.all([p1]).then(() => {
        this.loading = false;
        this.$refs.dialogAddAffectation.hide();
        this.addAffectationPopupOpened = false;
      });
    },
    importUsers() {
      if (this.files.length === 0) {
        this.getRef().error(this.$t('user.no_file_selected'));
        return;
      }

      this.getRef().showTotalLoader();
      userService.importUsers(this.files[0]).then(data => {
        this.getRef().success(this.$t('user.import_success'));
        this.$refs.dialogImport.hide();
      }).catch(e => {
        this.getRef().error(this.$t('user.import_error'));
      }).finally(() => {
        this.getRef().hideLoader();
      });
    }
  },
  computed: {
    uerListForCurrent() {
      return this.uerList.filter(uer => uer.agerId === this.current.agerId);
    },
    ceiListForCurrent() {
      return this.ceiList.filter(cei => cei.uerId === this.current.uerId);
    },
    isPasswordRequired() {
      return !this.isAgent;
    },
    isLoginRequired() {
      return !this.isAgent;
    },
    currentUGs() {
      if (null == this.current?.groups) return null;
      return this.ugList.find(ug => this.current?.groupIds == ug.id);
    },
    isAgent() {
      return this.current?.groupNames?.includes("AGENT");
    },
  },
  watch: {
    uerListForCurrent() {
      if (!this.uerListForCurrent.some(uer => uer.id === this.current.uerId))
        this.current.uerId = undefined;
    },
    ceiListForCurrent() {
      if (!this.ceiListForCurrent.some(cei => cei.id === this.current.ceiId))
        this.current.ceiId = undefined;
    },
    // Watch current.groups
    'current.groupIds': {
      handler: function (val) {
        if (this.loading || val === undefined) return;
        this.current.groups = val.map(groupId => this.ugList.find(ug => ug.id === groupId));
      },
      deep: true
    },
    "newAffectation.agerId": {
      handler: function (val) {
        if (val !== undefined) {
          if (!this.loading) {
            this.newAffectation.uerId = undefined;
            this.newAffectation.ceiId = undefined;
          }
          this.loading = true;
          this.relatedUerList = this.uerList.filter(uer => uer.agerId === this.newAffectation.agerId);
          this.relatedCeiList = this.ceiList.filter(cei => cei.agerId === this.newAffectation.agerId);
          this.loading = false;
        }
      },
      deep: true
    },
    "newAffectation.uerId": {
      handler: function (val) {
        if (val !== undefined) {
          if (!this.loading) {
            this.newAffectation.ceiId = undefined;
          }
          this.loading = true;
          this.relatedCeiList = this.ceiList.filter(cei => cei.uerId === this.newAffectation.uerId);
          this.loading = false;
        }
      },
      deep: true
    },
    "newAffectation.ceiId": {
      handler: function (val) {
        if (!this.loading && val !== undefined) {
          this.loading = true;
          this.newAffectation.uerId = this.ceiList.find(cei => cei.id === this.newAffectation.ceiId).uerId;
          this.newAffectation.agerId = this.ceiList.find(cei => cei.id === this.newAffectation.ceiId).agerId;
          this.loading = false;
        }
      },
      deep: true
    },
  }
}
</script>

<template v-if="!roleLoading">
  <AkFormList :ref="ref" :title="$t('user.list')">
    <template v-slot:action>
      <div class="action-bar" v-if="!isMobile">
        <span class="p-input-icon-left">
          <i class="pi pi-search" />
          <InputText v-model="filters['global'].value" :placeholder="$t('search_here')" />
        </span>
        <button v-if="this.canEditAdmin()"
          class="btn btn-inverse-primary float-right"
          @click="openCreateDialog()">
          <i class="fe fe-plus pr-1"/>
          {{ $t('add') }}
        </button>
        <button
           class="btn btn-inverse-primary"
           @click="exportList" :disabled="isExportDownloading">
          <i class="fe fe-download pr-1" v-if="!isExportDownloading"/>
          <i class="pi pi-spin pi-spinner mr-1" v-if="isExportDownloading"></i>
           {{ $t('user.exportList') }}
        </button>
      <button
          class="btn btn-inverse-primary"
          @click="openImportDialog">
        <i class="fe fe-upload pr-1"/>
        {{ $t('user.importList') }}
      </button>
      </div>
    </template>
    <template v-slot:search>
      <div class="row" v-if="isMobile">
        <div class="col-lg-12">
          <div class="card card-statistics mb-30">
            <div class="card-body action-card flex-start">
              <span class="p-input-icon-left">
                <i class="pi pi-search"/>
                <InputText v-model="filters['global'].value" :placeholder="$t('search_here')"/>
              </span>
              <button v-if="this.canEditAdmin()"
                      class="btn btn-inverse-primary float-right"
                      @click="openCreateDialog()">
                <i class="fe fe-plus pr-1"/>
                {{ $t('add') }}
              </button>
              <button
                  class="btn btn-inverse-primary"
                  @click="exportList">
                <i class="fe fe-download pr-1"/>
                {{ $t('user.exportList') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:list>
      <div class="row">
        <div class="col-lg-12">
          <div class="card card-statistics">
            <div class="card-body">
              <div class="table-responsive">
                <DataTable
                    :always-show-paginator="false" :loading="loading" :paginator="true" :rows="10"
                    :rowsPerPageOptions="[10,20,50]" :value="list" class="table" v-model:filters="filters"
                    :globalFilterFields="['lastName','firstName','email','tel','agerLabel','uerLabel','ceiLabel']"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    removableSort stripedRows sortMode="multiple" responsiveLayout="scroll">
                  <template #empty>
                    {{ $t("list_empty") }}
                  </template>
                  <Column field="lastName" :header="$t('user.last_name')" :sortable="true">
                    <template #body="slotProps">
                      {{ slotProps.data.lastName }}
                    </template>
                  </Column>
                  <Column field="firstName" :header="$t('user.first_name')" :sortable="true">
                    <template #body="slotProps">
                      {{ slotProps.data.firstName }}
                    </template>
                  </Column>
                  <Column field="email" :header="$t('user.email')" :sortable="true">
                    <template #body="slotProps">
                      {{ slotProps.data.email }}
                    </template>
                  </Column>
                  <Column field="tel" :header="$t('user.tel')" :sortable="true">
                    <template #body="slotProps">
                      {{ slotProps.data.tel }}
                    </template>
                  </Column>
                  <Column field="groupNames" :header="$t('group')" :sortable="true">
                    <template #body="slotProps">
                      {{ slotProps.data.groupNames !== undefined ? slotProps.data.groupNames.join(', ') : "" }}
                    </template>
                  </Column>
                  <Column field="agerLabel" :header="$t('ager_label')" :sortable="true">
                    <template #body="slotProps">
                      {{ slotProps.data.affectationsDTO?.affectations.length > 0 ? slotProps.data.affectationsDTO?.affectations[0].agerLabel : "" }}
                    </template>
                  </Column>
                  <Column field="uerLabel" :header="$t('uer_label')" :sortable="true">
                    <template #body="slotProps">
                      {{ slotProps.data.affectationsDTO?.affectations.length > 0 ? slotProps.data.affectationsDTO?.affectations[0].uerLabel : "" }}
                    </template>
                  </Column>
                  <Column field="ceiLabel" :header="$t('cei_label')" :sortable="true">
                    <template #body="slotProps">
                      {{ slotProps.data.affectationsDTO?.affectations.length > 0 ? slotProps.data.affectationsDTO?.affectations[0].ceiLabel : "" }}
                    </template>
                  </Column>
                  <Column
                      bodyStyle="text-align: right; overflow: visible"
                      headerStyle="width: 160px; text-align: center">
                    <template #body="slotProps">
                      <span
                          v-if=this.canEditAdmin()
                          class="btn btn-xs btn-inverse-primary pointer"
                          @click="openUpdateDialog(slotProps.data)">
                        <i class="fe fe-edit"/></span>
                      <span
                          v-if=this.canDeleteAdmin()
                          class="btn btn-xs btn-inverse-danger ml-1"
                          @click="openDeleteDialog(slotProps.data)">
                        <i class="fe fe-trash"/></span>
                    </template>
                  </Column>
                </DataTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:extra>
      <AkDialog ref="dialogCreate"
                :title="$t('user.create_dialog')"
                :validate-label="$t('add')"
                width="700px"
                @validate="this.create()">
        <div>

          <div class="form-row">
            <AkInputText :label="$t('user.last_name')"
                         v-model="current.lastName"
                         :validator="v$.current.lastName"
                         class-name="col-6"/>
            <AkInputText :label="$t('user.first_name')"
                         v-model="current.firstName"
                         :validator="v$.current.firstName"
                         class-name="col-6"/>
            <AkInputText :label="$t('user.tel')"
                         v-model="current.tel"
                         :validator="v$.current.tel"
                         class-name="col-6"/>
          </div>
          <div class="form-row">
            <AkDropdownMultiple :label="$t('user.ug_label')"
                                v-model="current.groupIds"
                                :validator="v$.current.groupIds"
                                :options=this.ugList
                                option-value="id"
                                option-label="name"
                                class-name="col-6"/>
          </div>
          <template v-if="current.groupIds !== undefined && current.groupIds.length > 0">
            <div class="form-row">
            <AkTitle title="Affectations" class-name="col-12"/>
            <DataTable :paginator=false
                       :value="current.affectationsDTO.affectations"
                       class="table"
                       :sortOrder=-1
                       removableSort
                       responsiveLayout="scroll"
                       stripedRows>
              <template #empty>
                {{ $t("list_empty") }}
              </template>
              <Column :header="$t('ager_label')">
                <template #body="slotProps">
                  <span>{{ slotProps.data.agerLabel }}</span>
                </template>
              </Column>
              <Column :header="$t('uer_label')">
                <template #body="slotProps">
                  <span>{{ slotProps.data.uerLabel }}</span>
                </template>
              </Column>
              <Column :header="$t('cei_label')">
                <template #body="slotProps">
                  <span>{{ slotProps.data.ceiLabel }}</span>
                </template>
              </Column>
              <Column>
                <template #body="slotProps">
                  <span
                      class="btn btn-xs btn-inverse-danger fa-pull-right"
                      @click="deleteAffectation(slotProps.data)">
                    <i class="fe fe-trash"/></span>
                </template>
              </Column>
              <template #footer>
                <span
                    class="btn btn-xs btn-inverse-info"
                    @click="openAddAffectationDialog()">
                  <i class="fe fe-plus-circle"/>
                </span>
              </template>
            </DataTable>
          </div>
          </template>
          <div class="form-row">
            <AkInputText :label="$t('user.emailLogin')"
                         v-if="isLoginRequired"
                         v-model="current.email"
                         :validator="v$.current.email"
                         class-name="col-6"/>

            <AkInputText :label="$t('user.password')"
                         v-if="isPasswordRequired"
                         inputType="password"
                         :validator="v$.current.password"
                         v-model="current.password"
                         class-name="col-6"/>


          </div>
        </div>
      </AkDialog>
      <AkDialog ref="dialogUpdate"
                :title="$t('user.update_dialog')"
                :validate-label="$t('update')"
                :cancel-label="$t('cancel')"
                width="700px"
                @cancel="this.cancelAddAffectation()"
                @validate="this.update()">
        <div>
          <Message v-for="msg of messagesUserPopup" :key="msg.content" :closable="msg.closable" :severity="msg.severity" @close="closeMessageCustom(messagesUserPopup, msg)">
            {{ msg.content }}
          </Message>
          <div class="form-row">
            <AkInputText :label="$t('user.last_name')"
                         v-model="current.lastName"
                         :validator="v$.current.lastName"
                         class-name="col-6"/>
            <AkInputText :label="$t('user.first_name')"
                         v-model="current.firstName"
                         :validator="v$.current.firstName"
                         class-name="col-6"/>
          </div>
          <div class="form-row">

            <AkInputText :label="$t('user.tel')"
                         v-model="current.tel"
                         :validator="v$.current.tel"
                         class-name="col-6"/>
          </div>
          <div class="form-row">
            <AkDropdownMultiple :label="$t('user.ug_label')"
                                v-model="current.groupIds"
                                :validator="v$.current.groupIds"
                                :options=this.ugList
                                option-value="id"
                                option-label="name"
                                class-name="col-6"/>
          </div>
          <template v-if="current.groupIds !== undefined && current.groupIds.length > 0">
            <div class="form-row">
            <AkTitle title="Affectations" class-name="col-12"/>
            <DataTable :paginator=false
                       :value="current.affectationsDTO.affectations"
                       class="table"
                       :sortOrder=-1
                       removableSort
                       responsiveLayout="scroll"
                       stripedRows>
              <template #empty>
                {{ $t("list_empty") }}
              </template>
              <Column :header="$t('ager_label')">
                <template #body="slotProps">
                  <span>{{ slotProps.data.agerLabel }}</span>
                </template>
              </Column>
              <Column :header="$t('uer_label')">
                <template #body="slotProps">
                  <span>{{ slotProps.data.uerLabel }}</span>
                </template>
              </Column>
              <Column :header="$t('cei_label')">
                <template #body="slotProps">
                  <span>{{ slotProps.data.ceiLabel }}</span>
                </template>
              </Column>
              <Column>
                <template #body="slotProps">
                  <span
                      class="btn btn-xs btn-inverse-danger fa-pull-right"
                      @click="deleteAffectation(slotProps.data)">
                    <i class="fe fe-trash"/></span>
                </template>
              </Column>
              <template #footer>
                <span
                    class="btn btn-xs btn-inverse-info"
                    @click="openAddAffectationDialog()">
                  <i class="fe fe-plus-circle"/>
                </span>
              </template>
            </DataTable>
          </div>
          </template>
          <div class="form-row">
            <AkInputText :label="$t('user.emailLogin')"
                         v-if="isLoginRequired"
                         v-model="current.email"
                         :validator="v$.current.email"
                         class-name="col-6"/>
            <AkInputText :label="$t('user.password_edition')"
                         v-if="isPasswordRequired"
                         inputType="password"
                         v-model="current.password"
                         :validator="v$.current.password"
                         class-name="col-6"/>
          </div>
        </div>
      </AkDialog>
      <AkDialog ref="dialogDelete"
                :auto-hide-on-validate="true"
                :cancel-label="$t('no')"
                :title="$t('delete_dialog')"
                :validate-label="$t('yes')"
                width="450px"
                @validate="this.delete()">
        <div class="confirmation-content">
          <i class="fe fe-alert-triangle mr-1" style="font-size: 2rem"/>
          <span>{{ $t('user.confirm_delete') }}</span>
        </div>
      </AkDialog>
      <AkDialog ref="dialogAddAffectation"
                :auto-hide-on-validate="false"
                :cancel-label="$t('cancel')"
                :title="$t('add_affectation_dialog')"
                :validate-label="$t('add')"
                width="600px"
                @validate="this.addAffectation()">
        <Message v-for="msg of messagesAddAffectationPopup" :key="msg.content" :closable="msg.closable" :severity="msg.severity" @close="closeMessageCustom(messagesAddAffectationPopup, msg)">
          {{ msg.content }}
        </Message>
        <div>
          <i class="fe fe-info mr-1" style="font-size: 2rem"/>
          <span>{{ $t('user.mandatory_field_depends_on_group') }}</span>
        </div>
        <div class="form-row">
          <AkDropdown :label="$t('ager_label')"
                      v-model="newAffectation.agerId"
                      :validator="v$.newAffectation.agerId"
                      :options=this.agerList
                      option-value="id"
                      option-label="label"
                      :show-clear=true
                      class-name="col-6"/>
          <AkDropdown :label="$t('uer_label')"
                      v-model="newAffectation.uerId"
                      :validator="v$.newAffectation.uerId"
                      v-if="newAffectation.agerId !== undefined && newAffectation.agerId !== null"
                      :options=relatedUerList
                      option-value="id"
                      option-label="label"
                      :show-clear=true
                      class-name="col-6"/>
          <AkDropdown :label="$t('cei_label')"
                      v-model="newAffectation.ceiId"
                      :validator="v$.newAffectation.ceiId"
                      v-if="newAffectation.uerId !== undefined && newAffectation.uerId !== null"
                      :options=relatedCeiList
                      option-value="id"
                      option-label="label"
                      :show-clear=true
                      class-name="col-6"/>
        </div>
      </AkDialog>
      <AkDialog ref="dialogImport"
                :auto-hide-on-validate="true"
                :cancel-label="$t('cancel')"
                :title="$t('user.import_dialog')"
                :validate-label="$t('import')"
                width="750px"
                @validate="this.importUsers()">
        <div class="confirmation-content">
          <div class="row">
            <div class="col-md-12">Choisissez le fichier .XSLX contenant l'organigramme des utilisateurs</div>
            <AkFileUpload v-model="this.files"
                          file-limit="1"
                          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                          label=""
                          class-name="col-md-12"/>
          </div>
        </div>
      </AkDialog>
    </template>
  </AkFormList>
</template>